import type { DeepKeys, FieldApi } from "@tanstack/react-form";

export function ariaTanstackFieldProps<TParentData, TName extends DeepKeys<TParentData>>(
    api: FieldApi<TParentData, TName>,
) {
    const {
        handleChange,
        handleBlur,
        state: { value, meta },
    } = api;
    return {
        value,
        onBlur: handleBlur,
        onChange: handleChange,
        isInvalid: meta.errors.length > 0,
    };
}
