/**
 * Add a separator between every element of an array.
 *
 * Similar to {@link Array.prototype.join}, but without forcing a string conversion.
 */
export function intersperse<T>(sep: T, arr: T[]): T[] {
    const result: T[] = [];
    let first = true;
    for (const elem of arr) {
        if (!first) {
            result.push(sep);
        }
        result.push(elem);
        first = false;
    }
    return result;
}
