import type { ProcessorCurrencyFee } from "@warrenio/api-spec/spec.oats.gen";
import type { EBillingAccount } from "../billingLogic.tsx";
import type { MethodBase } from "../PaymentMethod.tsx";

export function getCountryCurrencyFees(
    account: EBillingAccount,
    config: ProcessorCurrencyFee,
    country: string | null | undefined,
): ProcessorCurrencyFee | undefined {
    const baCountry = account.account.country?.toUpperCase();
    const cardCountry = country?.toUpperCase();
    if (!baCountry) {
        console.warn(`Account ${account.id} has no country set`);
        return undefined;
    }

    const forCountry = config.for_country;
    const countryExists: boolean = !!cardCountry || !!baCountry;
    if (!!forCountry && countryExists && forCountry !== cardCountry && forCountry !== baCountry) {
        return undefined;
    }

    return config;
}

export interface CalculatedFees {
    baseFee: number;
    feePercent: number;
    total: number;
}

export function getCalculatedFees(fees: MethodBase["fee"], amount: number, vat_percentage: number): CalculatedFees {
    const baseFee = fees?.service_fee?.flat_rate ?? 0;
    const feePercent = fees?.service_fee?.percentage ?? 0;

    const totalFee = baseFee + amount * (feePercent / 100);
    const vatMultiplier = 1 + vat_percentage / 100;
    const total = (amount + totalFee) * vatMultiplier;

    return { baseFee, feePercent, total };
}
